import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({title, metaDesc}) => {
    return (
        <Helmet>
            <html lang="en" />
            <title>{title}</title>
            <meta name="description" content={metaDesc} />
        </Helmet>
    )
}

export default SEO
